<template>
  <div class="account-list-wrap">
    <div class="button-wrap">
      <el-button
        v-if="!$route.path.includes('search')"
        type="primary"
        style="width: 100%"
        @click="openDialog('searchAccount')"
      >
        條件搜尋
      </el-button>
    </div>
    <el-table
      ref="listTable"
      :data="getAccountList"
      height="100%"
      style="width: 100%"
      header-cell-class-name="cus-table-header"
      row-class-name="cus-table-row"
      empty-text="尚無資料"
      class="search-form"
    >
      <el-table-column prop="user_id" label="帳號" width="150" />
      <el-table-column prop="user_name" label="姓名" width="110" />
      <el-table-column prop="permission_title" label="類別" width="110" />
      <el-table-column prop="store_info" label="可登入門市" :show-overflow-tooltip="true">
        <template #default="scope">
          <span>
            {{ scope.row.store_info.map((e) => e.shop_name).join('、') }}
          </span>
        </template>
      </el-table-column>
      <el-table-column prop="create_datetime" label="建立時間" width="180">
        <template #default="scope">
          <span>
            {{ formatDateTime('YYYY/MM/DD HH:mm', scope.row.create_datetime) }}
          </span>
        </template>
      </el-table-column>
      <el-table-column fixed="right" align="right" width="150">
        <template #default="scope">
          <el-button
            type="info"
            plain
            style="font-weight: normal; border: none; width: 110px"
            @click="handleClickDetail(scope.$index, scope.row)"
          >
            詳細
          </el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      class="pagination"
      v-model:currentPage="currentPage"
      v-model:page-size="getPageSize"
      layout="prev, pager, next, jumper"
      :total="getPageTotal"
      @current-change="handleCurrentChange"
    />
  </div>

  <!-- 彈窗 -->
  <component :is="dialogTemplate"></component>
</template>
<script>
import { ref, watch, defineAsyncComponent } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import { useDialog } from '@/utils/dialog';
import { useGetters, useMutations, useActions } from '@/utils/mapStore';
import { formatDateTime } from '@/utils/datetime';
export default {
  name: 'account-list',
  components: {
    AccountDialog: defineAsyncComponent(() => import('@/components/account/AccountDialog.vue')),
  },
  setup() {
    const route = useRoute();
    const router = useRouter();
    const { getAccountList, getPageSize, getPageTotal, getIsDetail } = useGetters('account', [
      'getAccountList',
      'getPageSize',
      'getPageTotal',
      'getIsDetail',
    ]);
    const SET_IS_DETAIL = useMutations('account', ['SET_IS_DETAIL']);
    const doGetUserList = useActions('account', ['doGetUserList']);
    //初始化
    const listTable = ref(null);
    const currentPage = ref(1);
    const query = { page: route.query.page ? route.query.page : 1 };
    router.push({ query, replace: true }).then(async () => {
      currentPage.value = Number(route.query.page);
      if (route.path === '/account/list' && !getIsDetail.value) {
        await doGetUserList({ start: (currentPage.value - 1) * getPageSize.value, type: 'init' });
      } else {
        SET_IS_DETAIL(false);
      }
    });
    //點擊分頁
    const getSearchCondition = useGetters('account', ['getSearchCondition']);
    const doSearchUserList = useActions('account', ['doSearchUserList']);
    const handleCurrentChange = async () => {
      const query = { page: currentPage.value };
      router.push({ query });
    };
    watch(
      () => route.query.page,
      async () => {
        if (route.query.page) {
          currentPage.value = Number(route.query.page);
          if (!getSearchCondition.value) {
            await doGetUserList({ start: (currentPage.value - 1) * getPageSize.value, type: 'page' });
          } else {
            await doSearchUserList({
              search: getSearchCondition.value,
              start: (currentPage.value - 1) * getPageSize.value,
              type: 'page',
            });
          }
          listTable.value.scrollBarRef.scrollTo(0, 0);
        }
      }
    );
    //修正再次搜尋時currentPage值
    watch(
      getSearchCondition,
      () => {
        currentPage.value = 1;
      },
      { deep: true }
    );

    //點擊詳細
    const SET_ACCOUNT_DETAIL = useMutations('account', ['SET_ACCOUNT_DETAIL']);
    const handleClickDetail = async (index, item) => {
      SET_ACCOUNT_DETAIL(item);
      router.push(route.path + '/detail');
    };

    //彈窗
    const { dialogTemplate, openDialog } = useDialog();
    return {
      formatDateTime,
      getAccountList,
      listTable,
      currentPage,
      getPageSize,
      getPageTotal,
      handleCurrentChange,
      handleClickDetail,
      dialogTemplate,
      openDialog,
    };
  },
};
</script>
<style lang="scss" scoped>
$padding-unit: 10px;
$row-height: 55px;
$pagination-height: 50px;
.account-list-wrap {
  width: 100%;
  height: 100%;
  padding: $padding-unit $padding-unit $pagination-height !important;
  position: relative;
  overflow: scroll;
  @include white-bg;
}
.button-wrap {
  position: absolute;
  top: calc($padding-unit + $row-height / 2);
  right: 22px;
  z-index: 10;
  transform: translateY(-50%);
  width: 110px;
}
.pagination {
  position: absolute;
  left: 50%;
  bottom: calc($pagination-height / 2);
  transform: translate(-50%, 50%);
}
</style>
